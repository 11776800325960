@import '../styles/variables.scss';

  .offersSection {
    h2 {
      color: $color-text-primary;
    }
    p {
      font-size: 1.125rem;
    }
 
  .offers {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .white_container{
    width: calc(#{$max-width} / 4);
    height: calc(#{$max-width} / 4);
    .imageContainer_white {
      background-color: white;
      width: 50%;
      height: 100%;
      .white {
        height: 100%;
        width: 50%;
        object-fit: cover;
      }
    }

  }

 .offer {
    width: calc(#{$max-width} / 2);
    height: calc(#{$max-width} / 4);
    display: flex;
    align-items: center;
    .imageContainer {
      width: 50%;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .paragraph_afterimage{
        padding:20px;
      }
      
     }
    .text {
      flex-basis: 50%;
      color: $color-text-secondary;
      font-size: 1.875rem;
      margin: 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      .price {
        font-size: 5.75rem;
        font-weight: bold;
        line-height: 5rem;
      }
      .priceInfo {
        margin-top: 1rem;
        font-size: 1.875rem;
        color: $color-text-primary;
      }

    }
  }

  
@media screen and (max-width: $max-width)  {
    .offers {
      height:unset;
      width: 100%;
      align-items: center;
      .offer {
        width: 100%;
        height: 100%;

        &:nth-child(2) {
          .imageContainer:nth-child(1) {
            order: 1;
          }
        }
        
      }
    }
    .white_container{
      display:none;
    }

  }

  @media screen and (max-width: $breakpoint) {

    .white_container{
      display:none;
    }

    .paragraph_afterimage{
      text-align: center;
    }

   .offers {
      flex-flow: column nowrap;
      height: unset;
      width: 100%;
      align-items: center;
      margin-bottom: 0;
      .offer {
        width: calc(100vw - (2 * #{$min-gap}));
        height: calc((100vw - (2 * #{$min-gap}) )/ 2);
      
        &:nth-child(2) {
          .imageContainer:nth-child(1) {
            order: 1;
          }
        }
      }
    }
  }
}
