@import '../../styles/variables.scss';
@import '../../styles/mixins/text';

.inviterForm {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 3rem;
  h2 {
    margin-top: 0;
  }
  p {
    margin-top: 0;
  }
  .form {
    .error {
      @include errorText;
    }
  }
  .textInputs {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    & > div {
      flex-basis: calc(50% - 0.5rem);
      margin-top: 1rem;
      &:nth-child(odd) {
        margin-right: 1rem;
      }
    }
  }
  .rules {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    a {
      color: $brand-grey;
      transition: color 0.2s linear;
      &:hover {
        color: darken($brand-grey, 5);
      }
    }
  }

  @media screen and (max-width: $breakpoint) {
    .textInputs {
      flex-flow: column nowrap;
      & > div {
        flex-basis: unset;
        &:nth-child(odd) {
          margin-right: 0;
        }
      }
    }
  }
}