@import '../../styles/variables.scss';
@import '../../styles/mixins/text';

  .inviteForm {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 3rem;

    .inviteesContainer {
      //height: 15rem;
      border: 1px solid transparent;
      display: flex;
      flex-flow: column nowrap;
    }
    .invitees, .notes {
      display: flex;
      flex-flow: column nowrap;
      //justify-content: space-between;
    }
    .invitees {
      width: 35%;
      min-width: 370px;
    }
    .notes {
      width: 65%;
      margin-right: 2rem;
    }
    .addInvitee {
      //margin-top: auto;
      p, button {
        font-size: 1.125rem;
        color: $color-text-secondary;
        font-weight: bold;
        margin: 0;
        padding: 0.2rem;
      }
      button {
        transition: color 0.2s linear;
        margin-left: auto;
        &:hover {
          color: darken($color-text-secondary, 5)
        }
      }
    }
    .error {
      @include errorText;
    }
    textarea {
      height: 14rem;
    }
    input, label {
      max-width: 21rem;
    }
    label {
      display: flex;
    }
    


    @media screen and (max-width: $breakpoint) {
      flex-flow: column nowrap;
      .invitees, .notes {
        width: 100%;
        min-width: unset;
      }
      .notes {
        margin-bottom: 2rem;
      }
      input, label {
       max-width: 25rem;
      }
    }
  }