@import '../styles/variables.scss';

.header {
  color: white;
  margin-bottom: 1rem;
  background: $brand-red url('../assets/img/hero-bg.jpg') no-repeat center center;
  background-size: cover;
  .logo {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 20%;
    max-width: 25rem;
    min-width: 15rem;
  }
  .container {
    width: 100%;
    height: 80vh;
    min-height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .box {
      background-color: $brand-red;
      width: 50vh;
      height: 50vh;
      max-width: 90%;
      min-height: 25rem;
      min-width: 25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      
      span {
        font-size: 2.5rem;
        text-align: center;
        line-height: 2.5rem;
        white-space: pre-line;
      }
      .price {
        font-size: 8rem;
        line-height: 8rem;
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: $breakpoint) {
    .container {
      height: 40vh;
      min-height: 25rem;
      .box {
        width: 25vh;
        height: 25vh;
        min-width: 12rem;
        min-height: 12rem;
        span {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        .price {
          font-size: 4rem;
          line-height: 4rem;
        }
      }
    }
  }
}
