@import './variables.scss';
@import url("https://use.typekit.net/zhq6ubg.css");

$main-font: ff-meta-web-pro, sans-serif;
$secondary-font: ff-meta-web-pro-condensed, sans-serif;

html {
  font-family: $main-font;
  font-weight: normal;
  font-size: 16px;
}

  h1 {
    color: $color-text-primary;
    margin: 0.5rem 0;
    text-align: center;
    font-family: $main-font;
    font-size: 3.25rem;
    font-weight: 700;
  }
  h2 {
    font-size: 2.25rem;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0 0 1rem 0;
  }

  p {
    font-size: 1.125rem;
  }


@media screen and (max-width: $breakpoint) {
  html {
    font-size: 14px;
  }
}