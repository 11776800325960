
@mixin submitButton {
  margin: 2rem 1rem 1rem 0;
  padding: 0.5rem 1rem;
  background-color: $brand-red;
  border: 0;
  color: white;
  width: 15rem;
  font-size: 1.125rem;
  cursor: pointer;
  transition: background-color 0.2s linear;
  font-weight: normal;
  text-decoration: none;
  &:hover {
    background-color: darken($brand-red, 5);
  }
}