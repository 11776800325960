@import '../../styles/variables.scss';

.mainView {
  position: relative;
  .infoContainer {
      text-align: center;
  }
}

@media screen and (max-width: $max-width) {
  .mainView {
 
  }
}