@import '../styles/variables.scss';

footer {
  font-size: 0.8rem;
  margin: auto auto 0 auto;
  padding: 1rem;
  text-align: left;
  color: white;
  background: $brand-red;
  align-self: flex-end;
  width: 100%;
  .container {
    max-width: 1000px;
    margin: auto;
    text-align: center;
  }
  a {
    color: #fff;
    &:hover {
      color: darken(#fff, 10);
    }
  }
}
