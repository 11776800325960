.spinner {
  margin: auto;
  position: relative;

  .loading-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #fff;
      border-radius: 100%;
      animation: loading-circle 1.2s infinite ease-in-out both;
    }
  }

  $deg: 0deg;
  $sec: -1.2s;
  @for $i from 1 through 12 {
    $deg: $deg + 30deg;
    $sec: $sec - 0.1s;
    .loading-circle#{$i} {
      transform: rotate(#{$deg});
    }

    .loading-circle#{$i}:before {
      animation-delay: #{$sec};
    }
  }
}

//animation

@keyframes loading-circle {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
