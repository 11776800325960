@import '../styles/variables.scss';
@import '../styles/mixins/buttons';

$topBar-height: 3rem;
$wrapper-height: 95vh;

.modal {
  opacity: 0;
  //transition: all 0.2s linear;
  .wrapper {
    overflow: hidden;
    width: 0;
    height: 0;
    transition: width 0.15s linear;
    
  }
  &.open {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5px);
    background-color: rgba(0,0,0,0.6);
  .wrapper {
    width: $max-width;
    max-width: 98%;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: $wrapper-height;
      box-shadow: 0 3px 6px rgba(#000, .16),
    				0 3px 6px rgba(#000, .23) ;
  }
  .topBar {
    height: $topBar-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0 0 0 1rem;
      font-size: 1.2rem;
    }
    button {
      text-decoration: none;
      margin: 0 1rem 0 0;
      display: flex;
      svg {
        width: 2rem;
        height: 2rem;
        fill: $brand-grey;
      }
    }
  }
  .scrollable {
    width: 100%;
    height: calc(#{$wrapper-height} - #{$topBar-height});
    overflow-y: scroll;
    padding-bottom: 2rem;
  }
  .header {
    position: relative;
  }
  .body {
    padding: 0 $min-gap;
    h3 {
      font-size: 2.25rem;
      margin-bottom: 1.5rem;
    }
    span {
      text-transform: capitalize;
    }
    .boldText {
      font-weight: bold;
      span {
        color: $color-text-secondary;
        text-decoration: underline;
        text-transform: none;
      }
    }
  }
  .buttons {
    padding: 0 $min-gap;
    
  }
  .submitButton {
    @include submitButton;
  }
}

}