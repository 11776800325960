$brand-red: #da1627;
$brand-grey: #4e4e4e;

$color-text-primary: #000;
$color-text-secondary: $brand-red;

$min-gap: 10px;

$max-width: 1042px;

$min-width: 751px;

$breakpoint: 750px;
