@import './variables.scss';
@import './typography.scss';
@import './mixins/buttons';

* {
  box-sizing: border-box;
  font-family: $main-font;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

main {
  max-width: $max-width;
  margin: 0 auto;
  width: 100%;
}

section {
  margin: 3rem 0;
}

label {
  text-align: left;
  font-size: 1rem;
}
input,
textarea {
  padding: 0.25rem;
  resize: none;
  font-family: $main-font;
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
  // &:invalid {
  //   border-color: $red;
  // }
}
input[type='checkbox'] {
  width: auto;
  margin: 1rem;
}
textarea {
  padding: 1rem;
}
button[type=submit] {
  @include submitButton;
}

button[type=button] {
  background-color: unset;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: $brand-grey;
  font-size: 1rem;
}
input[type=checkbox] {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #b2b2b2;
    margin: 0;
    padding: 6px 10px;
    font-size: 16px;
    color: #444444;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    margin-right: 0.5rem;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
}

fieldset {
  border: none;
  padding: 0;
}

@media screen and (max-width: $max-width) {
  main {
    padding: 0 $min-gap;
  }
}