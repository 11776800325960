@import '../../styles/variables.scss';
@import '../../styles/mixins/text';

.emailInput {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;
  button {
    color: $brand-grey;
    transition: color 0.2s linear;
    &:hover {
      color: darken($brand-grey, 10);
    }
  }
  .error {
    @include errorText;
  }
}